







































import { Component, Vue } from 'vue-property-decorator';
import I18n from '@/i18n/index';

@Component({
  components: {},
})
export default class Index extends Vue {
  private i18n = I18n;
}
