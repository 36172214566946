import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=70384e0a&"
import script from "./Index.vue?vue&type=script&lang=ts&"
export * from "./Index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/localization/pageList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Cquality-policy%5CIndex.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports